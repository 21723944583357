<script>
export default {
  data () {
    return {
      searchExpanded: false,
      search: {
        id: `${this.filter.field}_Search`,
        value: '',
        placeholder: this.$T('SearchPlaceholder')
      }
    }
  },
  methods: {
    toggleSearchExpanded (state = null) {
      this.searchExpanded = state !== null ? state : !this.searchExpanded

      this.$nextTick(() => {
        if (this.searchExpanded) {
          this.$refs.searchControl.$el.querySelector('input').focus()
        }
      })
    },
    searchItems (value) {
      const lowerValue = value.toLowerCase()

      this.filter.controls
        .forEach(c => {
          c.hide = !c.text.toLowerCase().includes(lowerValue)
        })
    },
    resetSearch () {
      this.search.value = ''
      this.searchItems(this.search.value)
    }
  }
}
</script>
