<script>
import { SEARCH_FILTER_EMPTY_CONTROL_VALUES, SEARCH_FILTER_EXPANDED_DEFAULT } from '@/assets/js/config/client'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    const storedExpanded = JSON.parse(window.sessionStorage.getItem(this.filter.id))

    return {
      expanded: storedExpanded !== null ? storedExpanded : SEARCH_FILTER_EXPANDED_DEFAULT
    }
  },
  computed: {
    filterSetCount () {
      return this.filter.controls.filter(c => c.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type]).length
    },
    allFilterSet () {
      return this.filter.controls.every(c => c.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type])
    },
    noneFilterSet () {
      return this.filter.controls.every(c => c.value === SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type])
    }
  },
  methods: {
    toggleExpanded (state = null) {
      this.expanded = state !== null ? state : !this.expanded
      window.sessionStorage.setItem(this.filter.id, this.expanded)
      this.$emit(`filter:${this.expanded ? 'expanding' : 'collapsing'}`)
    },
    setControls (value = null) {
      if (value !== null) {
        this.filter.controls.forEach(c => { c.value = value })
        this.$emit('filter:change', this.filter)
      }
    },
    resetControls () {
      this.filter.controls.forEach(c => { c.value = SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type] })
      this.$emit('filter:change', this.filter)
    },
    updateControl (control, value) {
      const udpateControl = this.filter.controls.find(c => c.id === control.id) || {}

      udpateControl.value = value
      this.$emit('filter:change', this.filter)
    }
  }
}
</script>
